/* apply a natural box layout model to all elements, but allowing components to change */
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html {
  display: flex;
  min-height: 100%;
}
body,
#root {
  font-family: Diatype sans-serif;
  display: flex;
  flex: 1;
  margin: 0;
}
